import React, { useState, useRef, useEffect } from 'react';
import {
  ChildTasks_childConsultations,
  ChildTasks_child_assignedActivities,
  ChildTasks_child_assignedCustomActivities,
} from '../../../../../graphql/queries/types/ChildTasks';
import { Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import ActivityRow from './components/ActivityRow';
import { WordTrackingSegment } from '../../../../Client/WordTrackingRoute/WordsTable/styles';
import {
  TableHeaderStickyContainer,
  HeaderStickyContainer,
  SectionTitleStickyContainer,
  CategoryBadge,
  SectionListCountContainer,
  SycnScrollContainer,
  EmptySectionContainer,
  HideContainer,
  SectionDivider,
  Arrow,
  ArrowContainer,
  MetricsGridContainer,
  MetricsContainer,
  FilterGridContainer,
  FilterButton,
  FilterButtonText,
  ToolBoxContainer,
  ToolboxActionContainer,
} from './styles';
import { catOrder } from '../../../../../util/Category';
import TableHeader from './components/TableHeader';
import { Description, UserComment, MetricsNumber, MetricsTitle } from '../../../../../style';
import { useInView } from 'react-intersection-observer';
import TableDropdown from './components/TableDropdown';
import { CalendarIcon } from './components/TableDropdown/styles';
import { optionType } from './components/TableDropdown/Item';
import { RouteContentContainer, RouteTitleContainer } from '../../layout/styles';
import { Typography } from 'antd';
import CustomActivityRow from './components/CustomActivityRow';

interface Props {
  consultations: ChildTasks_childConsultations[];
  assignedActivities: ChildTasks_child_assignedActivities[];
  assignedCustomActivities: ChildTasks_child_assignedCustomActivities[];
}

const TasksComponent = ({ consultations, assignedActivities, assignedCustomActivities }: Props) => {
  const { t } = useTranslation();
  const [selectedConsultation, setSelectedConsultation] = useState<Array<number>>([]);
  const [selectedGroup, setSelectedGroup] = useState<Array<number>>([0]);
  const [showTimeDropdown, setShowTimeDropdown] = useState<boolean>(false);
  const [showGroupDropdown, setShowGroupDropdown] = useState<boolean>(false);
  const [divHeight, setDivHeight] = useState<number>(0);
  const { ref, inView, entry } = useInView();
  const componentHeader = useRef(document.createElement('div'));
  const categoryTopsScrollRef = useRef<Array<HTMLDivElement | null>>([]);
  const categoryContentsScrollRef = useRef<Array<HTMLDivElement | null>>([]);
  const defaultTopScrollRef = useRef(document.createElement('div'));
  const defaultContentScrollRef = useRef(document.createElement('div'));
  const [clickedIndex, setClickedIndex] = useState({});
  let contentLastScrollLeft = 0;
  const groupView = !selectedGroup.includes(0);
  const filterConsultationView = selectedConsultation.length > 0;

  useEffect(() => {
    setDivHeight(componentHeader.current.offsetHeight);
  }, [showTimeDropdown, showGroupDropdown]);

  const { Title } = Typography;

  const consultationOptions: optionType[] = [];
  consultations.forEach((consultation, index) => {
    const option = {
      key: Number(consultation.id),
      text: consultation.consultationTime.split('T')[0],
      value: index,
    };
    consultationOptions.push(option);
  });

  const groupOptions: optionType[] = [
    {
      key: 0,
      text: t('therapist.childInfo.activity.filters.none'),
      value: 0,
    },
    {
      key: 1,
      text: t('therapist.childInfo.activity.general.category'),
      value: 1,
    },
    {
      key: 2,
      text: t('therapist.childInfo.activity.filters.consultation'),
      value: 2,
    },
  ];

  // create 'all consultation activity array' that store 'each consultation activity array'
  const AllConsultationActivities: ChildTasks_childConsultations[] = [];
  const AllConsultationCustomActivities: ChildTasks_childConsultations[] = [];
  consultations.forEach((consData) => {
    if (consData) {
      AllConsultationActivities.push(consData);
      AllConsultationCustomActivities.push(consData);
    }
  });

  // create sync scroll
  const onCategoryTopScroll = (e, index: number) => {
    const currentContentRef = categoryContentsScrollRef.current[index];
    if (currentContentRef !== null) {
      currentContentRef.scrollLeft = e.target.scrollLeft;
    }
  };
  const onCategoryContentScroll = (e, index: number) => {
    if (e.target.scrollLeft !== contentLastScrollLeft) {
      const currentTopRef = categoryTopsScrollRef.current[index];
      if (currentTopRef !== null) {
        currentTopRef.scrollLeft = e.target.scrollLeft;
      }
      contentLastScrollLeft = e.target.scrollLeft;
    }
  };
  const onTopScroll = (e) => {
    defaultContentScrollRef.current.scrollLeft = e.target.scrollLeft;
  };
  const onContentScroll = (e) => {
    if (e.target.scrollLeft !== contentLastScrollLeft) {
      defaultTopScrollRef.current.scrollLeft = e.target.scrollLeft;
      contentLastScrollLeft = e.target.scrollLeft;
    }
  };

  // create accordion toggle
  const handleClick = (index) => () => {
    setClickedIndex((state) => ({
      ...state, // <-- copy previous state
      [index]: !state[index], // <-- update value by index key
    }));
  };

  // render data
  const filterByConsultationData = selectedConsultation.map((selectedIndex) => consultations[selectedIndex]);
  const CurrentConsultationData = filterConsultationView ? filterByConsultationData : AllConsultationActivities;
  const consultationsActivitiesArray = CurrentConsultationData.map((conData) => conData.activities);
  const consultationsCustomActivitiesArray = CurrentConsultationData.map((conData) => conData.customActivities);
  const groupByCategoryActivitiesArray = catOrder.map((catId) =>
    consultationsActivitiesArray.flat().filter((activity) => activity.mainSkill.category.id === catId),
  );
  const groupByConsultationActivitiesArray = consultationsActivitiesArray.map((consAcc) => consAcc);

  // find 'child assigned activity' by 'consultation activity id'
  const selectedConAccIds = consultationsActivitiesArray.map((conAcc) => conAcc.map((c) => c.id)).flat();
  const selectedConAccInAssignedAcc = assignedActivities.filter((aac) => selectedConAccIds.indexOf(aac.id) !== -1);
  const selectedConAccInAssignedCustomAcc = assignedCustomActivities.filter(
    (aac) => selectedConAccIds.indexOf(aac.id) !== -1,
  );
  // assigned Activity
  const AssignedActivity = consultationsActivitiesArray.reduce((accumulator, accData) => {
    return accumulator + accData.length;
  }, 0);
  const AssignedCustomActivity = consultationsCustomActivitiesArray.reduce((accumulator, accData) => {
    return accumulator + accData.length;
  }, 0);
  // Trained Activity
  const TrainedActivity = selectedConAccInAssignedAcc.filter((aac) =>
    aac.checkInCount ? aac.checkInCount > 0 : null,
  ).length;
  const TrainedCustomActivity = selectedConAccInAssignedCustomAcc.filter((aac) =>
    aac.checkInCount ? aac.checkInCount > 0 : null,
  ).length;
  // Check in
  const CheckIn = selectedConAccInAssignedAcc.reduce((accumulator, accData) => {
    const checkInCount = accData.checkInCount ? accData.checkInCount : 0;
    return accumulator + checkInCount;
  }, 0);
  const CheckInCustomActivity = selectedConAccInAssignedCustomAcc.reduce((accumulator, accData) => {
    const checkInCount = accData.checkInCount ? accData.checkInCount : 0;
    return accumulator + checkInCount;
  }, 0);
  const groupData = [consultationsActivitiesArray, groupByCategoryActivitiesArray, groupByConsultationActivitiesArray];

  const handleChangeTimePeriod = (value: number) => {
    if (selectedConsultation.includes(value)) {
      const newData = selectedConsultation.filter((selected) => selected !== value);
      setSelectedConsultation(newData);
    } else {
      setSelectedConsultation([...selectedConsultation, value]);
    }
  };

  const handleChangeGroup = (value: number) => {
    setSelectedGroup([value]);
    setClickedIndex({});
  };

  const handleClickGroup = () => {
    setShowGroupDropdown(!showGroupDropdown);
    setSelectedGroup([0]);
  };

  const handleClickTime = () => {
    setShowTimeDropdown(!showTimeDropdown);
    setSelectedConsultation([]);
  };

  if (consultations.length === 0) {
    return (
      <div>
        <Grid>
          <Grid.Column>
            <h2 style={{ borderLeft: '5px solid #47E4C2', paddingLeft: '20px' }}>
              {t('therapist.dashboard.myClient.clientInfo.notes.clientRecord')}
            </h2>
          </Grid.Column>
        </Grid>
        <div style={{ paddingLeft: '20px' }}>
          <div data-cy="noTasksAssigned" style={{ marginTop: '30px' }}>
            No tasks or homework have been assigned
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <RouteTitleContainer>
        <Title level={4}>{t('therapist.dashboard.myClient.clientInfo.tasks.thisWeeksTask')}</Title>
      </RouteTitleContainer>
      <ToolBoxContainer id="therapist_child_activity_header" ref={componentHeader}>
        <ToolboxActionContainer>
          <FilterButton toggle={showTimeDropdown} onClick={handleClickTime}>
            <FilterButtonText toggle={showTimeDropdown}>
              {t('therapist.childInfo.activity.filters.filter')}
            </FilterButtonText>
          </FilterButton>
          <FilterButton toggle={showGroupDropdown} onClick={handleClickGroup}>
            <FilterButtonText toggle={showGroupDropdown}>
              {t('therapist.childInfo.activity.filters.group')}
            </FilterButtonText>
          </FilterButton>
        </ToolboxActionContainer>
        <FilterGridContainer>
          {showTimeDropdown && (
            <TableDropdown
              icon={
                <div style={{ width: '15px' }}>
                  <CalendarIcon selected={filterConsultationView} className="calendar alternate outline"></CalendarIcon>
                </div>
              }
              selectedText={
                t('therapist.childInfo.activity.filters.consultation') +
                (filterConsultationView
                  ? ': ' +
                    selectedConsultation.map((data) => ' ' + consultations[data].consultationTime.substring(0, 10))
                  : '')
              }
              options={consultationOptions}
              handleOptionChange={handleChangeTimePeriod}
              selected={filterConsultationView}
              listHeader={t('therapist.childInfo.activity.filters.consultationDate')}
              selectedItem={selectedConsultation}
              type={'multiSelect'}
            />
          )}
          {showGroupDropdown && (
            <TableDropdown
              icon={
                <div style={{ width: '15px' }}>
                  <CalendarIcon selected={groupView} className="th list"></CalendarIcon>
                </div>
              }
              selectedText={
                `${t('therapist.childInfo.activity.filters.group')}: ` + groupOptions[selectedGroup[0]].text
              }
              options={groupOptions}
              handleOptionChange={handleChangeGroup}
              selected={groupView}
              listHeader={t('therapist.childInfo.activity.filters.groupBy')}
              selectedItem={selectedGroup}
              type={'option'}
            />
          )}
        </FilterGridContainer>
      </ToolBoxContainer>
      <RouteContentContainer>
        <MetricsGridContainer>
          <MetricsContainer>
            <MetricsTitle>{t('therapist.childInfo.activity.statistics.assignedActivities')}</MetricsTitle>
            <MetricsNumber>{AssignedActivity + AssignedCustomActivity}</MetricsNumber>
          </MetricsContainer>
          <MetricsContainer>
            <MetricsTitle>{t('therapist.childInfo.activity.statistics.trainedActivities')}</MetricsTitle>
            <MetricsNumber>{TrainedActivity + TrainedCustomActivity}</MetricsNumber>
          </MetricsContainer>
          {/* {filterConsultationView && (
            <MetricsContainer>
              <MetricsTitle>New Trained Activities</MetricsTitle>
              <MetricsNumber>0000</MetricsNumber>
            </MetricsContainer>
          )} */}
          <MetricsContainer>
            <MetricsTitle>{t('therapist.childInfo.activity.statistics.checkIns')}</MetricsTitle>
            <MetricsNumber>{CheckIn + CheckInCustomActivity}</MetricsNumber>
          </MetricsContainer>
        </MetricsGridContainer>
        <WordTrackingSegment>
          {groupView ? (
            <div ref={ref}>
              {groupData[selectedGroup[0]].map((accArray, accArrayIndex) => (
                <>
                  <div>
                    <HeaderStickyContainer inView={inView} stickyHeight={divHeight}>
                      <SectionTitleStickyContainer inView={inView} stickyHeight={divHeight}>
                        <ArrowContainer hide={clickedIndex[accArrayIndex]} onClick={handleClick(accArrayIndex)}>
                          <Arrow className="caret down" />
                        </ArrowContainer>
                        <CategoryBadge catIndex={accArrayIndex}>
                          <UserComment>
                            {selectedGroup[0] === 1 && t(`utils.fiveCategories.${catOrder[accArrayIndex]}.title`)}
                            {selectedGroup[0] === 2 && consultations[accArrayIndex].consultationTime.substring(0, 10)}
                          </UserComment>
                        </CategoryBadge>
                        <SectionListCountContainer>
                          <Description>{accArray.length}</Description>
                        </SectionListCountContainer>
                      </SectionTitleStickyContainer>
                      <HideContainer hide={clickedIndex[accArrayIndex]}>
                        {accArray.length !== 0 && (
                          <TableHeaderStickyContainer group={groupView} stickyHeight={divHeight}>
                            <SycnScrollContainer
                              ref={(el) => (categoryTopsScrollRef.current[accArrayIndex] = el)}
                              onScroll={(e) => onCategoryTopScroll(e, accArrayIndex)}
                            >
                              <TableHeader group={groupView} />
                            </SycnScrollContainer>
                          </TableHeaderStickyContainer>
                        )}
                      </HideContainer>
                    </HeaderStickyContainer>
                    <HideContainer hide={clickedIndex[accArrayIndex]}>
                      <SycnScrollContainer
                        ref={(el) => (categoryContentsScrollRef.current[accArrayIndex] = el)}
                        onScroll={(e) => onCategoryContentScroll(e, accArrayIndex)}
                      >
                        {accArray.length === 0 && (
                          <EmptySectionContainer>
                            <UserComment>No activity. Can assign activity in next consultation.</UserComment>
                          </EmptySectionContainer>
                        )}
                        {accArray.map((activity, index) => (
                          <ActivityRow
                            key={activity.id}
                            activity={activity}
                            checkInCount={assignedActivities.filter((aac) => aac.id === activity.id)[0].checkInCount}
                            assignedTime={
                              selectedGroup[0] === 2
                                ? consultations[accArrayIndex].consultationTime
                                : consultations[0].consultationTime
                            }
                          />
                        ))}
                      </SycnScrollContainer>
                    </HideContainer>
                  </div>
                  <SectionDivider hide={clickedIndex[accArrayIndex]}></SectionDivider>
                </>
              ))}
            </div>
          ) : (
            <div ref={ref}>
              <HeaderStickyContainer inView={inView} stickyHeight={divHeight}>
                <TableHeaderStickyContainer group={groupView} stickyHeight={divHeight}>
                  <SycnScrollContainer ref={defaultTopScrollRef} onScroll={(e) => onTopScroll(e)}>
                    <TableHeader group={groupView} />
                  </SycnScrollContainer>
                </TableHeaderStickyContainer>
              </HeaderStickyContainer>
              <SycnScrollContainer ref={defaultContentScrollRef} onScroll={(e) => onContentScroll(e)}>
                {CurrentConsultationData.map((condata) =>
                  condata.activities.map((activity) => {
                    const activityId = activity.id;
                    const checkInCount = assignedActivities.find((aac) => aac.id === activityId)?.checkInCount || 0;

                    return (
                      <ActivityRow
                        key={activityId}
                        activity={activity}
                        checkInCount={checkInCount}
                        assignedTime={condata.consultationTime}
                      />
                    );
                  }),
                )}
                {CurrentConsultationData.map((condata) =>
                  condata.customActivities.map((customActivity) => {
                    const customActivityId = customActivity.id;
                    const checkInCount =
                      assignedCustomActivities.find((aac) => aac.id === customActivityId)?.checkInCount || 0;
                    return (
                      <CustomActivityRow
                        key={customActivityId}
                        customActivity={customActivity}
                        checkInCount={checkInCount}
                        assignedTime={condata.consultationTime}
                      />
                    );
                  }),
                )}
              </SycnScrollContainer>
            </div>
          )}
        </WordTrackingSegment>
      </RouteContentContainer>
    </>
  );
};

export default TasksComponent;
