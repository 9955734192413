import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Space, Typography } from 'antd';
import { ChildTasks_child_assignedCustomActivities } from '../../../../../../../../graphql/queries/types/ChildTasks';
import WeeklyCheckInView from '../../../../../../../Client/TaskRoute/ActivityTab/CustomActivityModal/component/WeeklyCheckInView';
import { SkillBadge } from '../../styles';
import { renderStringToList } from '../../../../../../../../util/renderStringToList';
import { BackgroundColor } from '../../../../../../../Client/WordTrackingRoute/styles';
import { CurrentWeekday } from '../../../../../../../../util/Date';
import { GET_CUSTOM_ACTIVITIES_FOLDER_PATH, getSignedUrl } from '../../../../../../../../helpers/functions';

interface Props {
  customActivity: ChildTasks_child_assignedCustomActivities;
}

interface Attachment {
  id: string;
  data: string;
  attachmentType: string;
  signedUrl?: string;
}

const OverallTab = ({ customActivity }: Props) => {
  const { t } = useTranslation();
  const { Title, Text } = Typography;
  const introduction = renderStringToList(customActivity.introduction);
  const parentInteraction = renderStringToList(customActivity.parentInteraction);
  const [attachmentsWithUrls, setAttachmentsWithUrls] = useState<Attachment[]>([]);

  useEffect(() => {
    const fetchSignedUrls = async () => {
      if (customActivity.customActivityAttachments && customActivity.customActivityAttachments?.length > 0) {
        const attachmentsWithSignedUrls = await Promise.all(
          customActivity.customActivityAttachments.map(async (attachment: any) => {
            if (attachment.attachmentType === 'file') {
              const signedUrl = await getSignedUrl(
                GET_CUSTOM_ACTIVITIES_FOLDER_PATH(
                  customActivity.skill.id,
                  customActivity.skill.name,
                  customActivity.name,
                ),
                attachment.data,
                process.env.REACT_APP_AWS_UPLOADER_S3_BUCKET ?? '',
              );
              return { ...attachment, signedUrl };
            }
            return attachment;
          }),
        );
        setAttachmentsWithUrls(attachmentsWithSignedUrls);
      }
    };
    fetchSignedUrls();
  }, [customActivity]);

  return (
    <Space size="large" direction="vertical" style={{ width: '-webkit-fill-available' }}>
      <div>
        <Title level={5}>{t(`client.task.activities.overall.weekRecord`)}</Title>
        <WeeklyCheckInView customActivity={customActivity} currentWeekday={CurrentWeekday()} />
      </div>
      <div>
        <Title level={5}>{t(`client.task.activities.overall.skill`)}</Title>
        <SkillBadge BackgroundColor={BackgroundColor}>{customActivity.skill.name}</SkillBadge>
      </div>
      <div>
        <Title level={5}>{t(`client.task.activities.overall.summary`)}</Title>
        <Text>{introduction}</Text>
      </div>
      <div>
        <Title level={5}>{t(`client.task.activities.overall.parentInteraction`)}</Title>
        <Text>{parentInteraction}</Text>
      </div>
      <div>
        <Title level={5}>{t(`client.task.activities.overall.attachments`)}</Title>
        {attachmentsWithUrls.map((attachment) => (
          <div key={attachment.id}>
            <a
              href={attachment.attachmentType === 'file' ? attachment.signedUrl : attachment.data}
              target="_blank"
              rel="noopener noreferrer"
            >
              {attachment.data}
            </a>
          </div>
        ))}
      </div>
    </Space>
  );
};

export default OverallTab;
