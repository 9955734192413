import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TherapistChildContext } from '../../TherapistChildContext';
import { RouteContentContainer, RouteTitleContainer } from '../../layout/styles';
import { Card, PageHeader, Space, Typography } from 'antd';
import { Redirect, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import DefaultDetails from '../../components/ConsultationAndPlanCard/DefaultDetails';
import ReactHtmlParser from 'react-html-parser';
import ActivityTable from './ActivityTable';
import PlanGoalTable from './PlanGoalTable';
import CustomActivityTable from './CustomActivityTable';

interface MatchParams {
  consultationId: string;
}

const PlanDetailsRoute = () => {
  const { consultationId } = useParams<MatchParams>();
  const history = useHistory();
  const match = useRouteMatch();
  const { t } = useTranslation();
  const {
    publishedPlan,
    child: { id: childId },
    childGoalConsultations,
    childTaskConsultations,
    setShowActivityModal,
    setShowGoalModal,
    setShowCustomActivityModal,
  } = useContext(TherapistChildContext);
  const { Title, Text } = Typography;
  const plan = publishedPlan.filter((plan) => plan.id === consultationId)[0];
  const planGoal = childGoalConsultations.filter((consultation) => consultation.id === consultationId)[0]
    .modifyGoalRecords;
  const planActivity = childTaskConsultations.filter((childTask) => childTask.id === consultationId)[0]
    .modifyActivities;
  const planCustomActivity = childTaskConsultations.filter((childTask) => childTask.id === consultationId)[0]
    .modifyCustomActivities;

  if (!match) {
    return <Redirect to="/" />;
  }

  const onSelectActivity = (activityId: string) => {
    setShowActivityModal(true);
    history.push(`${match.url}?activity=${activityId}`);
  };

  const onSelectCustomActivity = (customActivityId: string) => {
    setShowCustomActivityModal(true);
    history.push(`${match.url}?customActivity=${customActivityId}`);
  };

  const onSelectGoal = (goalId: string) => {
    setShowGoalModal(true);
    history.push(`${match.url}?consultation=${consultationId}&goal=${goalId}`);
  };

  if (!plan) {
    return <>no</>;
  }
  return (
    <>
      <RouteTitleContainer style={{ padding: 0, height: '72px' }}>
        <PageHeader
          onBack={() => history.push(`/child/${childId}/plan?tab=3`)}
          title={`${t('therapist.childInfo.general.todoType.plan')} ${plan.planSequence}`}
        />
      </RouteTitleContainer>
      <RouteContentContainer>
        <Space direction="vertical" size="large" style={{ display: 'flex' }}>
          <div>
            <Title level={4}>{t('therapist.childInfo.planDetails.consultationDetails')}</Title>
            <Card>
              <DefaultDetails consultation={plan}></DefaultDetails>
            </Card>
          </div>
          <div>
            <Title level={4}>{t('therapist.childInfo.planDetails.planNotes')}</Title>
            <Card>
              <Title level={5}>{t('therapist.dashboard.myClient.clientInfo.notes.mainComplaint')}</Title>
              <Text>
                {plan.notes ? plan.notes.mainComplaint : t('therapist.dashboard.myClient.clientInfo.notes.noNotes')}
              </Text>
              <Title level={5}>{t('therapist.dashboard.myClient.clientInfo.notes.internalNotes')}</Title>
              <Text>
                {plan.notes ? plan.notes.internalNotes : t('therapist.dashboard.myClient.clientInfo.notes.noNotes')}
              </Text>
              <Title level={5}>{t('therapist.dashboard.myClient.clientInfo.notes.commentsForParents')}</Title>
              <Text>
                {plan.notes
                  ? ReactHtmlParser(plan.notes.commentForParents)
                  : t('therapist.dashboard.myClient.clientInfo.notes.noNotes')}
              </Text>
            </Card>
          </div>
          <div>
            <Title level={4}>{t('therapist.dashboard.myClient.clientInfo.notes.progressOfGoals')}</Title>
            <Card>
              <PlanGoalTable planGoal={planGoal} onSelect={onSelectGoal} />
            </Card>
          </div>
          <div>
            <Title level={4}>{t('therapist.childInfo.planDetails.assignedActivity')}</Title>
            <Card>
              <ActivityTable activityList={planActivity} onSelect={onSelectActivity}></ActivityTable>
            </Card>
          </div>
          <div>
            <Title level={4}>{t('therapist.childInfo.planDetails.assignedCustomActivity')}</Title>
            <Card>
              <CustomActivityTable
                customActivityList={planCustomActivity}
                onSelect={onSelectCustomActivity}
              ></CustomActivityTable>
            </Card>
          </div>
        </Space>
      </RouteContentContainer>
    </>
  );
};

export default React.memo(PlanDetailsRoute);
