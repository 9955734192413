import gql from 'graphql-tag';

export const CHILD_TASKS = gql`
  query ChildTasks($childId: ID!) {
    childConsultations(childId: $childId) {
      id
      consultationTime
      activities {
        id
        name
        summary
        mediaUrl
        mainSkill {
          id
          name
          category {
            id
            name
          }
          subcategory {
            id
            name
          }
        }
        parentInteraction
      }
      customActivities {
        id
        name
        customActivityAttachments {
          id
          attachmentType
          data
        }
        introduction
        parentInteraction
        checkInCount
        checkInCountDaily
        skill {
          id
          name
          category {
            id
            name
          }
          subcategory {
            id
            name
          }
        }
      }
    }
    child(childId: $childId) {
      id
      assignedActivities {
        id
        name
        mediaUrl
        done
        mainSkill {
          id
          name
          category {
            id
            name
          }
          subcategory {
            id
            name
          }
        }
        skills {
          id
          name
          category {
            id
            name
          }
        }
        summary
        parentInteraction
        scenario
        setup
        frequency
        tools
        steps
        parentReaction
        notes
        extension
        checkInCount
        checkInCountDaily
        interactionTactics
      }
      assignedCustomActivities {
        id
        name
        customActivityAttachments {
          id
          attachmentType
          data
        }
        introduction
        parentInteraction
        skill {
          id
          name
          category {
            id
            name
          }
          subcategory {
            id
            name
          }
        }
        checkInCount
        checkInCountDaily
      }
    }
    childProgress(childId: $childId) {
      id
      weeklyCheckIns {
        startDate
        endDate
        checkInCount
        trainedActivityCount
        assignedActivityCount
        newlyTrainedActivityCount
      }
    }
  }
`;
