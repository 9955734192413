import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Space, Typography, Image, Tabs } from 'antd';
import { ChildTasks_child_assignedCustomActivities } from '../../../../../../../graphql/queries/types/ChildTasks';
import { BackgroundColor } from '../../../../../../Client/WordTrackingRoute/styles';
import OverallTab from './component/OverallTab';
import { SkillBadge } from '../styles';
import therapistIcon from '../../../../../../Client/LibraryRoute/Blog/assets/therapist.png';

interface Props {
  customActivity: ChildTasks_child_assignedCustomActivities;
}

const ParentView = ({ customActivity }: Props) => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const { Title, Text } = Typography;

  return (
    <Space size="large" direction="vertical" style={{ width: '-webkit-fill-available' }}>
      <Space size="middle" style={{ display: 'flex', width: '-webkit-fill-available' }}>
        <div>
          <Image src={therapistIcon} width={100} height={100} />
        </div>
        <div>
          <SkillBadge BackgroundColor={BackgroundColor}>
            <Text>{customActivity.skill.name}</Text>
          </SkillBadge>
          <Title level={4}>{customActivity.name}</Title>
          <Text type="secondary">{t(`client.task.activities.selective`)}</Text>
        </div>
      </Space>
      <Tabs defaultActiveKey="1">
        <TabPane tab={t(`client.task.activities.overall.title`)} key="1">
          <OverallTab customActivity={customActivity} />
        </TabPane>
      </Tabs>
    </Space>
  );
};

export default ParentView;
