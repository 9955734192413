import { Divider, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChildTasks_child_assignedCustomActivities } from '../../../../../../../../graphql/queries/types/ChildTasks';
import { findCatOrderIndexByCatId } from '../../../../../../../../util/Category';
import { BackgroundColor } from '../../../../../../../Client/WordTrackingRoute/styles';
import { CategoryBadge } from '../../../styles';
import { DetailsContainer, DetailsDataContainer, DetailsTitleContainer, SkillBadge } from '../../styles';

interface Props {
  customActivity: ChildTasks_child_assignedCustomActivities;
}

const SkillDetails = ({ customActivity }: Props) => {
  const { t } = useTranslation();
  const { Text } = Typography;
  const catOrderIndex = findCatOrderIndexByCatId(customActivity.skill.category.id);

  return (
    <>
      <DetailsContainer>
        <DetailsTitleContainer>
          <Text strong>{t('therapist.childInfo.activity.general.category')}</Text>
        </DetailsTitleContainer>
        <DetailsDataContainer>
          <CategoryBadge catIndex={catOrderIndex}>
            <Text>{customActivity.skill.category.name}</Text>
          </CategoryBadge>
        </DetailsDataContainer>
      </DetailsContainer>
      <DetailsContainer>
        <DetailsTitleContainer>
          <Text strong>{t('therapist.childInfo.activity.general.subcategory')}</Text>
        </DetailsTitleContainer>
        <DetailsDataContainer>
          <Text>{customActivity.skill.subcategory.name}</Text>
        </DetailsDataContainer>
      </DetailsContainer>
      <DetailsContainer>
        <DetailsTitleContainer>
          <Text strong>{t('therapist.childInfo.activity.general.mainSkill')}</Text>
        </DetailsTitleContainer>
        <DetailsDataContainer>
          <SkillBadge BackgroundColor={BackgroundColor}>
            <Text>{customActivity.skill.name}</Text>
          </SkillBadge>
        </DetailsDataContainer>
      </DetailsContainer>
      {customActivity.skill && (
        <>
          <Divider plain orientation="left" orientationMargin="0">
            <Text strong>{t('therapist.childInfo.activity.activityModal.skills')}</Text>
          </Divider>
          <SkillBadge BackgroundColor={BackgroundColor}>
            <Text>{customActivity.skill.name}</Text>
          </SkillBadge>
        </>
      )}
    </>
  );
};

export default SkillDetails;
