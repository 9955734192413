import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { ChildTasks_child_assignedCustomActivities } from '../../../../../../graphql/queries/types/ChildTasks';
import { findCatOrderIndexByCatId } from '../../../../../../util/Category';
import { DataContainer } from '../../../../../Client/WordTrackingRoute/WordsTable/styles';
import { TherapistChildContext } from '../../../TherapistChildContext';
import { ActivityRowContainer, EllipsisData, ActivityStickyColumnContainer, CategoryBadge } from '../styles';
import { FaUsers } from 'react-icons/fa';

interface Props {
  customActivity: ChildTasks_child_assignedCustomActivities;
  checkInCount: number | null;
  assignedTime: string;
}

const CustomActivityRow = ({ customActivity, checkInCount, assignedTime }: Props) => {
  const { t } = useTranslation();
  const catOrderIndex = findCatOrderIndexByCatId(customActivity.skill.category.id);
  const { setShowCustomActivityModal } = useContext(TherapistChildContext);
  const match = useRouteMatch();
  const history = useHistory();

  if (!match) {
    return <Redirect to="/" />;
  }

  const handleShowCustomActivityModal = (customActivityId: string) => {
    setShowCustomActivityModal(true);
    history.push(`${match.url}?customActivity=${customActivityId}`);
  };

  return (
    <ActivityRowContainer onClick={() => handleShowCustomActivityModal(customActivity.id)} isChecked={false}>
      <ActivityStickyColumnContainer isChecked={false}>
        <DataContainer center={false}>
          <EllipsisData>
            {customActivity.name + '   '} <FaUsers />
          </EllipsisData>
        </DataContainer>
      </ActivityStickyColumnContainer>
      <DataContainer center={false}>
        {checkInCount ? (
          <span style={{ color: '#5abaae' }}>{checkInCount}</span>
        ) : (
          <span style={{ color: 'red' }}>{checkInCount}</span>
        )}
      </DataContainer>
      <DataContainer center={false}>
        <EllipsisData>{assignedTime.substring(0, 10)}</EllipsisData>
      </DataContainer>
      <DataContainer center={false}>
        <EllipsisData>{customActivity.skill.name}</EllipsisData>
      </DataContainer>
      <DataContainer center={false}>
        <CategoryBadge catIndex={catOrderIndex}>{customActivity.skill.category.name}</CategoryBadge>
      </DataContainer>
      <DataContainer center={false}>
        <EllipsisData>{customActivity.skill.subcategory.name}</EllipsisData>
      </DataContainer>
    </ActivityRowContainer>
  );
};

export default CustomActivityRow;
