import React, { useContext, useMemo } from 'react';
import { Switch } from 'react-router';
import { Layout } from 'antd';
import { PlanGoalObj, TherapistChildContext, TherapistChildContextProvider } from './TherapistChildContext';
import { TherapistContext } from '../TherapistContextt';
import ReturnMessageComponent from '../../common/ReturnMessageComponent';
import BasicInfoDrawer from './components/BasicInfoDrawer';
import ChildSider from './layout/ChildSider';
import ChildHeader from './layout/ChildHeader';
import ChildContent from './layout/ChildContent';
import ActivityModal from './route/TasksRoute/ActivityModal';
import * as qs from 'query-string';
import GoalModal from './components/GoalModal';
import CustomActivityModal from './route/TasksRoute/CustomActivityModal';

const ChildInfoRoutes = () => {
  return (
    <Switch>
      <TherapistChildContextProvider>
        <ChildInfoComponent />
      </TherapistChildContextProvider>
    </Switch>
  );
};

const ChildInfoComponent = () => {
  const { returnMessage } = useContext(TherapistContext);
  const {
    assignedActivities,
    assignedCustomActivities,
    childGoalConsultations,
    showUserInfoDrawer,
    setShowUserInfoDrawer,
    showActivityModal,
    setShowActivityModal,
    showCustomActivityModal,
    setShowCustomActivityModal,
    showGoalModal,
    setShowGoalModal,
  } = useContext(TherapistChildContext);
  const { activity, goal, consultation, customActivity } = qs.parse(window.location.search);

  const [goalId, consultationId] = useMemo(() => {
    if (goal === null || consultation === null) {
      return ['', ''];
    }
    const goalId = goal as string;
    const consultationId = consultation as string;
    return [goalId, consultationId];
  }, [goal, consultation]);

  const activityId = useMemo(() => {
    if (activity === null) {
      return '';
    }
    const activityId = activity as string;
    return activityId;
  }, [activity]);

  const customActivityId = useMemo(() => {
    if (customActivity === null) {
      return '';
    }
    const customActivityId = customActivity as string;
    return customActivityId;
  }, [customActivity]);

  const activityData = assignedActivities.filter((assignedActivities) => assignedActivities.id === activityId)[0];
  const customActivityData = assignedCustomActivities.filter(
    (assignedCustomActivities) => assignedCustomActivities.id === customActivityId,
  )[0];
  const goalData: PlanGoalObj = childGoalConsultations
    .filter((data) => data.id === consultationId)[0]
    ?.modifyGoalRecords?.filter((data) => data.id === goalId)[0];

  return (
    <Layout>
      {returnMessage && <ReturnMessageComponent returnMessage={returnMessage} />}
      <BasicInfoDrawer show={showUserInfoDrawer} setShow={setShowUserInfoDrawer}></BasicInfoDrawer>
      {activityId && (
        <ActivityModal
          show={showActivityModal}
          setShow={setShowActivityModal}
          activityData={activityData}
        ></ActivityModal>
      )}
      {customActivityId && (
        <CustomActivityModal
          show={showCustomActivityModal}
          setShow={setShowCustomActivityModal}
          customActivityData={customActivityData}
        ></CustomActivityModal>
      )}
      {consultationId && goalId && (
        <GoalModal
          show={showGoalModal}
          setShow={setShowGoalModal}
          goalData={goalData}
          consultationId={consultationId}
        ></GoalModal>
      )}
      <ChildHeader></ChildHeader>
      <Layout style={{ backgroundColor: 'white' }}>
        <ChildSider></ChildSider>
        <ChildContent></ChildContent>
      </Layout>
    </Layout>
  );
};

export default ChildInfoRoutes;
