import gql from 'graphql-tag';
import { Category } from '../../types';

export const CATEGORIES_QUERY = gql`
  query Category {
    categories {
      id
      name
      subcategories {
        id
        name
        skills {
          id
          uid
          name
        }
        skillsWithActivities {
          id
          name
        }
        skillsWithCustomActivities {
          id
          name
        }
      }
    }
  }
`;

export interface Data {
  categories: Category[];
}
