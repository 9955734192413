import React from 'react';
import { Timeline, Typography } from 'antd';
import { RocketTwoTone, ThunderboltTwoTone } from '@ant-design/icons';
import {
  ChildTasks_child_assignedActivities,
  ChildTasks_child_assignedCustomActivities,
} from '../../../../../../../../graphql/queries/types/ChildTasks';
import { BackgroundColor } from '../../../../../../../Client/WordTrackingRoute/styles';
import { NotionBlueLight, NotionGrayLight, NotionPurpleLight } from '../../../styles';
import { HistoryBadge, HistoryListContainer } from '../../styles';

interface Props {
  customActivity: ChildTasks_child_assignedCustomActivities;
}

const ActivityHistory = ({ customActivity }: Props) => {
  const { Text } = Typography;

  const UserBadgeColor = (userType: string) => {
    if (userType === 'therapist') {
      return NotionBlueLight;
    } else {
      return NotionPurpleLight;
    }
  };

  return (
    <>Upcoming feature</>
    // <Timeline style={{ paddingTop: '20px' }}>
    //   <Timeline.Item dot={<ThunderboltTwoTone twoToneColor="#5abaae" translate={undefined} />}>
    //     <HistoryListContainer>
    //       <Text>Check in</Text>
    //       <HistoryBadge BackgroundColor={BackgroundColor} style={{ margin: '0 5px' }}>
    //         <Text>+1</Text>
    //       </HistoryBadge>
    //       <Text>by</Text>
    //       <HistoryBadge BackgroundColor={UserBadgeColor('parent')} style={{ margin: '0 5px' }}>
    //         <Text>雯妈</Text>
    //       </HistoryBadge>
    //     </HistoryListContainer>
    //     <Text type="secondary">on 2021-09-18 10:31pm Friday</Text>
    //   </Timeline.Item>
    //   <Timeline.Item dot={<ThunderboltTwoTone twoToneColor="#5abaae" translate={undefined} />}>
    //     <HistoryListContainer>
    //       <Text>Check in</Text>
    //       <HistoryBadge BackgroundColor={BackgroundColor} style={{ margin: '0 5px' }}>
    //         <Text>+1</Text>
    //       </HistoryBadge>
    //       <Text>by</Text>
    //       <HistoryBadge BackgroundColor={UserBadgeColor('parent')} style={{ margin: '0 5px' }}>
    //         <Text>雯妈</Text>
    //       </HistoryBadge>
    //     </HistoryListContainer>
    //     <Text type="secondary">on 2021-09-18 10:31pm Friday</Text>
    //   </Timeline.Item>
    //   <Timeline.Item dot={<ThunderboltTwoTone twoToneColor="#5abaae" translate={undefined} />}>
    //     <HistoryListContainer>
    //       <Text>Check in</Text>
    //       <HistoryBadge BackgroundColor={BackgroundColor} style={{ margin: '0 5px' }}>
    //         <Text>+1</Text>
    //       </HistoryBadge>
    //       <Text>by</Text>
    //       <HistoryBadge BackgroundColor={UserBadgeColor('parent')} style={{ margin: '0 5px' }}>
    //         <Text>雯妈</Text>
    //       </HistoryBadge>
    //     </HistoryListContainer>
    //     <Text type="secondary">on 2021-09-18 10:31pm Friday</Text>
    //   </Timeline.Item>
    //   <Timeline.Item dot={<RocketTwoTone twoToneColor="red" translate={undefined} />}>
    //     <HistoryListContainer>
    //       <Text>Assigned by</Text>
    //       <HistoryBadge BackgroundColor={UserBadgeColor('therapist')} style={{ margin: '0 5px' }}>
    //         <Text>Yu Ling</Text>
    //       </HistoryBadge>
    //       <Text>therapist</Text>
    //     </HistoryListContainer>
    //     <HistoryListContainer>
    //       <Text type="secondary">on consultation 5</Text>
    //       <HistoryBadge BackgroundColor={NotionGrayLight}>
    //         <Text>2022-02-14</Text>
    //       </HistoryBadge>
    //     </HistoryListContainer>
    //   </Timeline.Item>
    //   <Timeline.Item dot={<ThunderboltTwoTone twoToneColor="#5abaae" translate={undefined} />}>
    //     <HistoryListContainer>
    //       <Text>Check in</Text>
    //       <HistoryBadge BackgroundColor={BackgroundColor} style={{ margin: '0 5px' }}>
    //         <Text>+1</Text>
    //       </HistoryBadge>
    //       <Text>by</Text>
    //       <HistoryBadge BackgroundColor={UserBadgeColor('parent')} style={{ margin: '0 5px' }}>
    //         <Text>雯妈</Text>
    //       </HistoryBadge>
    //     </HistoryListContainer>
    //     <Text type="secondary">on 2021-09-18 10:31pm Friday</Text>
    //   </Timeline.Item>
    //   <Timeline.Item dot={<ThunderboltTwoTone twoToneColor="#5abaae" translate={undefined} />}>
    //     <HistoryListContainer>
    //       <Text>Check in</Text>
    //       <HistoryBadge BackgroundColor={BackgroundColor} style={{ margin: '0 5px' }}>
    //         <Text>+1</Text>
    //       </HistoryBadge>
    //       <Text>by</Text>
    //       <HistoryBadge BackgroundColor={UserBadgeColor('parent')} style={{ margin: '0 5px' }}>
    //         <Text>雯妈</Text>
    //       </HistoryBadge>
    //     </HistoryListContainer>
    //     <Text type="secondary">on 2021-09-18 10:31pm Friday</Text>
    //   </Timeline.Item>
    //   <Timeline.Item dot={<ThunderboltTwoTone twoToneColor="#5abaae" translate={undefined} />}>
    //     <HistoryListContainer>
    //       <Text>Check in</Text>
    //       <HistoryBadge BackgroundColor={BackgroundColor} style={{ margin: '0 5px' }}>
    //         <Text>+1</Text>
    //       </HistoryBadge>
    //       <Text>by</Text>
    //       <HistoryBadge BackgroundColor={UserBadgeColor('parent')} style={{ margin: '0 5px' }}>
    //         <Text>雯妈</Text>
    //       </HistoryBadge>
    //     </HistoryListContainer>
    //     <Text type="secondary">on 2021-09-18 10:31pm Friday</Text>
    //   </Timeline.Item>
    //   <Timeline.Item dot={<ThunderboltTwoTone twoToneColor="#5abaae" translate={undefined} />}>
    //     <HistoryListContainer>
    //       <Text>Check in</Text>
    //       <HistoryBadge BackgroundColor={BackgroundColor} style={{ margin: '0 5px' }}>
    //         <Text>+1</Text>
    //       </HistoryBadge>
    //       <Text>by</Text>
    //       <HistoryBadge BackgroundColor={UserBadgeColor('parent')} style={{ margin: '0 5px' }}>
    //         <Text>雯妈</Text>
    //       </HistoryBadge>
    //     </HistoryListContainer>
    //     <Text type="secondary">on 2021-09-18 10:31pm Friday</Text>
    //   </Timeline.Item>
    //   <Timeline.Item dot={<RocketTwoTone twoToneColor="red" translate={undefined} />}>
    //     <HistoryListContainer>
    //       <Text>Assigned by</Text>
    //       <HistoryBadge BackgroundColor={UserBadgeColor('therapist')} style={{ margin: '0 5px' }}>
    //         <Text>Yu Ling</Text>
    //       </HistoryBadge>
    //       <Text>therapist</Text>
    //     </HistoryListContainer>
    //     <HistoryListContainer>
    //       <Text type="secondary">on consultation 3</Text>
    //       <HistoryBadge BackgroundColor={NotionGrayLight}>
    //         <Text>2021-10-07</Text>
    //       </HistoryBadge>
    //     </HistoryListContainer>
    //   </Timeline.Item>
    //   <Timeline.Item dot={<ThunderboltTwoTone twoToneColor="#5abaae" translate={undefined} />}>
    //     <HistoryListContainer>
    //       <Text>Check in</Text>
    //       <HistoryBadge BackgroundColor={BackgroundColor} style={{ margin: '0 5px' }}>
    //         <Text>+1</Text>
    //       </HistoryBadge>
    //       <Text>by</Text>
    //       <HistoryBadge BackgroundColor={UserBadgeColor('parent')} style={{ margin: '0 5px' }}>
    //         <Text>雯妈</Text>
    //       </HistoryBadge>
    //     </HistoryListContainer>
    //     <Text type="secondary">on 2021-09-18 10:31pm Friday</Text>
    //   </Timeline.Item>
    //   <Timeline.Item dot={<RocketTwoTone twoToneColor="red" translate={undefined} />}>
    //     <HistoryListContainer>
    //       <Text>Assigned by</Text>
    //       <HistoryBadge BackgroundColor={UserBadgeColor('therapist')} style={{ margin: '0 5px' }}>
    //         <Text>Yu Ling</Text>
    //       </HistoryBadge>
    //       <Text>therapist</Text>
    //     </HistoryListContainer>
    //     <HistoryListContainer>
    //       <Text type="secondary">on consultation 2</Text>
    //       <HistoryBadge BackgroundColor={NotionGrayLight}>
    //         <Text>2021-09-14</Text>
    //       </HistoryBadge>
    //     </HistoryListContainer>
    //   </Timeline.Item>
    // </Timeline>
  );
};

export default ActivityHistory;
