import React, { useContext, Fragment, useEffect, useState } from 'react';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import { JS_COOKIE } from '../../../../util/Cookie';
import { Segment, Grid, Icon } from 'semantic-ui-react';
import {
  Consultation_consultation_child_goals_skill_category,
  Consultation_consultation_child_goals_preskills,
  Consultation_consultation_child_goals_preskills_info,
} from '../../../../graphql/queries/types/Consultation';
import {
  Category_categories_subcategories_skills,
  Category_categories,
  Category_categories_subcategories,
} from '../../../../graphql/queries/types/Category';

import { GoStarFill } from 'react-icons/go';
import { eval_backend_api_address } from '../../../../constants/config';
import axios from 'axios';
import { FaSitemap, FaUsers } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';

const toggleSwitchStyle = `
  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #00b5ad !important;
  }
`;

interface Props {
  category: Consultation_consultation_child_goals_skill_category | Category_categories;
  setSkill?: React.Dispatch<React.SetStateAction<Category_categories_subcategories_skills | null>>;
  setPreskills?: React.Dispatch<React.SetStateAction<Consultation_consultation_child_goals_preskills[]>>;
  setSubCategory?: React.Dispatch<React.SetStateAction<Category_categories_subcategories | null>>;
  preskills?: Consultation_consultation_child_goals_preskills[];
  goalPreSkills?: Consultation_consultation_child_goals_preskills_info[];
}

const TherapistConsultationScreenSkillsComponent = ({
  category,
  setSkill,
  setSubCategory,
  setPreskills,
  preskills,
  goalPreSkills,
}: Props) => {
  const [weakerSkillList, setWeakerSkillList] = useState<string[]>([]);
  const [showAllSkills, setShowAllSkills] = useState(false);
  const { t } = useTranslation();
  const {
    consultation: {
      child: {
        client: {
          user: { uuid },
        },
      },
    },
  } = useContext(TherapistConsultationScreenContext);

  function getGuideSkillIds(obj): string[] {
    const skillIds: string[] = [];

    function findSkillIds(obj: any) {
      for (const key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          findSkillIds(obj[key]);
        } else {
          if (key === 'skillId') {
            skillIds.push(obj[key].toString());
          }
        }
      }
    }

    findSkillIds(obj);
    return skillIds;
  }

  const getWeakerSkills = async () => {
    const token = JS_COOKIE.get('token');
    const userUuid = uuid;
    const res = await axios.get(eval_backend_api_address + 'api/results/getResultByUserUuid', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        userUuid,
      },
    });

    if (res) {
      setWeakerSkillList(getGuideSkillIds(res.data.weakerSkills));
      return res;
    }
  };

  useEffect(() => {
    getWeakerSkills();
  }, []);

  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = toggleSwitchStyle;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  const renderSkillsComponent = () => {
    if (setSkill && setSubCategory) {
      const c = category as Category_categories;

      return (
        <Fragment>
          <div
            style={{
              marginBottom: '10px',
              marginTop: '15px',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '15px',
            }}
          >
            {/* Left-aligned icons */}
            <div style={{ display: 'flex', alignItems: 'center', gap: '15px', color: '#666' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <FaSitemap style={{ color: '#00b5ad', fontSize: '12px' }} />
                <span style={{ fontSize: '12px' }}>{t('therapist.consultationScreen.progress.skills.activities')}</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <FaUsers style={{ color: '#00b5ad', fontSize: '12px' }} />
                <span style={{ fontSize: '12px' }}>
                  {t('therapist.consultationScreen.progress.skills.customActivities')}
                </span>
              </div>
            </div>

            {/* Right-aligned switch */}
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Switch
                checked={showAllSkills}
                onChange={(checked) => setShowAllSkills(checked)}
                onColor="#00b5ad"
                offColor="#dddddd"
                checkedIcon={false}
                uncheckedIcon={false}
                height={20}
                width={40}
                handleDiameter={16}
              />
              <span style={{ fontSize: '12px', color: '#666' }}>
                {t('therapist.consultationScreen.progress.skills.showMoreSkills')}
              </span>
            </div>
          </div>

          {c.subcategories.map((subcategory: Category_categories_subcategories) => {
            const filteredSkills = showAllSkills
              ? subcategory.skills
              : subcategory.skills.filter(
                  (s) =>
                    subcategory.skillsWithActivities.some((activity) => activity.id === s.id) ||
                    subcategory.skillsWithCustomActivities.some((activity) => activity.id === s.id),
                );

            return filteredSkills.length > 0 ? (
              <Grid.Column key={subcategory.id} textAlign="center">
                <Segment.Group>
                  <Segment style={{ backgroundColor: '#D9FFF9', color: '#007C7A' }}>
                    <h4>
                      {subcategory.name}
                      <Icon name="angle down" color="teal" size="large"></Icon>
                    </h4>
                  </Segment>
                  {filteredSkills
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((s: Category_categories_subcategories_skills) => (
                      <div key={s.id}>
                        <Segment
                          onClick={() => {
                            const subC = subcategory as Category_categories_subcategories;
                            setSubCategory(subC);
                            setSkill(s);
                          }}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            color: preskills && preskills.some((skill) => skill.id == s.id) ? 'teal' : 'black',
                            fontWeight: weakerSkillList.includes(s.id) ? '7' : 'default',
                          }}
                          data-cy="subcategoriesSkillList"
                        >
                          {/* {weakerSkillList.includes(s.uid) && (
                          <GoStarFill
                            style={{
                              marginRight: '5px',
                              fontSize: '12px',
                            }}
                          />
                        )} */}
                          {`${s.name}`}
                          {/* {weakerSkillList.includes(s.uid) && (
                          <GoStarFill
                            style={{
                              marginLeft: '5px',
                              fontSize: '12px',
                            }}
                          />
                        )} */}
                          <FaSitemap
                            style={{
                              marginLeft: '5px',
                              marginRight: '5px',
                              fontSize: '12px',
                              color: subcategory.skillsWithActivities.some((activity) => activity.id === s.id)
                                ? '#00b5ad' // teal color for active
                                : '#d3d3d3', // light gray for inactive
                            }}
                          />
                          <FaUsers
                            style={{
                              marginRight: '5px',
                              fontSize: '12px',
                              color: subcategory.skillsWithCustomActivities.some((activity) => activity.id === s.id)
                                ? '#00b5ad' // teal color for active
                                : '#d3d3d3', // light gray for inactive
                            }}
                          />
                        </Segment>
                      </div>
                    ))}
                </Segment.Group>
              </Grid.Column>
            ) : null;
          })}
        </Fragment>
      );
    }

    if (setPreskills && goalPreSkills) {
      const c = category as Consultation_consultation_child_goals_skill_category;
      return (
        <Fragment>
          <div
            style={{
              marginBottom: '10px',
              marginTop: '15px',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '15px',
            }}
          >
            {/* Left-aligned icons */}
            <div style={{ display: 'flex', alignItems: 'center', gap: '15px', color: '#666' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <FaSitemap style={{ color: '#00b5ad', fontSize: '12px' }} />
                <span style={{ fontSize: '12px' }}>{t('therapist.consultationScreen.progress.skills.activities')}</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <FaUsers style={{ color: '#00b5ad', fontSize: '12px' }} />
                <span style={{ fontSize: '12px' }}>
                  {t('therapist.consultationScreen.progress.skills.customActivities')}
                </span>
              </div>
            </div>

            {/* Right-aligned switch */}
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Switch
                checked={showAllSkills}
                onChange={(checked) => setShowAllSkills(checked)}
                onColor="#00b5ad"
                offColor="#dddddd"
                checkedIcon={false}
                uncheckedIcon={false}
                height={20}
                width={40}
                handleDiameter={16}
              />
              <span style={{ fontSize: '12px', color: '#666' }}>
                {t('therapist.consultationScreen.progress.skills.showMoreSkills')}
              </span>
            </div>
          </div>

          {c.subcategories.map((subcategory) => {
            const filteredSkills = showAllSkills
              ? subcategory.skills
              : subcategory.skills.filter(
                  (s) =>
                    subcategory.skillsWithActivities?.some((activity) => activity.id === s.id) ||
                    subcategory.skillsWithCustomActivities?.some((activity) => activity.id === s.id),
                );

            return filteredSkills.length > 0 ? (
              <Grid.Column key={subcategory.id} textAlign="center">
                <Segment.Group>
                  <Segment style={{ backgroundColor: '#D9FFF9', color: '#007C7A' }}>
                    <h4>
                      {subcategory.name}
                      <Icon name="angle down" color="teal" size="large"></Icon>
                    </h4>
                  </Segment>
                  {filteredSkills
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((s) => (
                      <div key={s.id}>
                        <Segment
                          onClick={() => {
                            const gp = goalPreSkills.find((o) => {
                              return o.skillId === s.id;
                            });
                            if (gp && gp.mastered) {
                              return;
                            }

                            setPreskills((prevState) => {
                              let result;
                              const sPreskill = {
                                ...s,
                                mastered: gp ? gp.mastered : false,
                              } as Consultation_consultation_child_goals_preskills;
                              if (prevState.some((skill) => skill.id == s.id)) {
                                result = prevState.filter((skill) => skill.id !== s.id);
                              } else {
                                result = [...prevState, sPreskill];
                              }
                              return result;
                            });
                          }}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            color: preskills && preskills.some((skill) => skill.id == s.id) ? 'teal' : 'black',
                          }}
                          data-cy="subcategoriesPreskillList"
                        >
                          {s.name}
                          <FaSitemap
                            style={{
                              marginLeft: '5px',
                              marginRight: '5px',
                              fontSize: '12px',
                              color: subcategory.skillsWithActivities?.some((activity) => activity.id === s.id)
                                ? '#00b5ad' // teal color for active
                                : '#d3d3d3', // light gray for inactive
                            }}
                          />
                          <FaUsers
                            style={{
                              marginRight: '5px',
                              fontSize: '12px',
                              color: subcategory.skillsWithCustomActivities?.some((activity) => activity.id === s.id)
                                ? '#00b5ad' // teal color for active
                                : '#d3d3d3', // light gray for inactive
                            }}
                          />
                        </Segment>
                      </div>
                    ))}
                </Segment.Group>
              </Grid.Column>
            ) : null;
          })}
        </Fragment>
      );
    }
  };

  return (
    <div style={{ marginTop: '10px' }}>
      <Segment textAlign="center">
        <h4>
          {category.name}
          <Icon name="angle down" color="teal" size="large"></Icon>
        </h4>
      </Segment>
      <Grid columns={2} stackable>
        {renderSkillsComponent()}
      </Grid>
    </div>
  );
};

export default TherapistConsultationScreenSkillsComponent;
