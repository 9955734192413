import gql from 'graphql-tag';

export const CONSULTATION_QUERY = gql`
  query Consultation($consultationId: ID!) {
    consultation(consultationId: $consultationId) {
      id
      consultationTime
      accepted
      canceled
      published
      child {
        id
        name
        gender
        dob
        childSixMonthGoal {
          id
          description
        }
        consultations {
          id
          consultationTime
          notes {
            id
            mainComplaint
            internalNotes
          }
        }
        client {
          id
          user {
            id
            uuid
            name
            email
          }
        }
        product {
          id
          name
          description
          owner
          maxConsultation
          productTypesName
        }
        pictureUrl
        completedTasks
        lastAssignedActivities {
          id
          name
          summary
          checkInCount
        }
        assignedActivities {
          id
          name
          summary
          checkInCount
        }
        assignedCustomActivities {
          id
          name
          skill {
            id
            name
          }
          customActivityAttachments {
            id
            attachmentType
            data
          }
          customActivityCheckins {
            id
          }
          introduction
          parentInteraction
          createdAt
        }
        lastAssignedCustomActivities {
          id
          name
          skill {
            id
            name
          }
          customActivityAttachments {
            id
            attachmentType
            data
          }
          customActivityCheckins {
            id
          }
          introduction
          parentInteraction
          createdAt
        }
        activityChildren {
          id
          activity {
            id
            name
            summary
            steps
            interactionTactics
            mainSkill {
              id
              name
            }
          }
          consultation {
            id
            consultationTime
          }
        }
        customActivityChildren {
          id
          customActivity {
            id
            name
            introduction
            parentInteraction
            skill {
              id
              name
            }
            customActivityAttachments {
              id
              attachmentType
              data
            }
          }
          consultation {
            id
            consultationTime
          }
        }
        goals {
          id
          description
          metric
          initNumerator
          initDenominator
          currentNumerator
          currentDenominator
          successNumerator
          successDenominator
          achieved
          skill {
            id
            name
            category {
              id
              name
              subcategories {
                id
                name
                skills {
                  id
                  name
                }
                skillsWithActivities {
                  id
                  name
                }
                skillsWithCustomActivities {
                  id
                  name
                }
              }
            }
            subcategory {
              id
              name
            }
          }
          preskills {
            id
            name
          }
          goalPreskills {
            skillId
            mastered
          }
          goalRecords {
            id
            consultation {
              id
              consultationTime
            }
            currentNumerator
            currentDenominator
          }
        }
        previousConsultation {
          id
          consultationTime
          activities {
            id
            name
          }
          customActivities {
            id
            name
            introduction
            parentInteraction
            customActivityAttachments {
              id
              attachmentType
              data
            }
          }
        }
        remainingHomeworks {
          id
          description
          submissionDate
          format
          finished
        }
      }
      therapist {
        id
        consultationUrl
        cnConsultationUrl
        user {
          id
          name
          locale
        }
      }
      notes {
        id
        mainComplaint
        internalNotes
        commentForParents
      }
    }
  }
`;
