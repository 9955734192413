import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-apollo';
import {
  ChildConsultationNotes_child,
  ChildConsultationNotes_consultations,
  ChildConsultationNotes_consultations_uploadedVideos as UploadedVideosObj,
} from '../../../graphql/queries/types/ChildConsultationnotes';
import { useParams } from 'react-router-dom';
import { CHILD_CONSULTATION_NOTES } from '../../../graphql/queries/childConsultationNotes';
import {
  ChildConsultationNotes as ChildConsultationData,
  ChildConsultationNotesVariables as ChildConsultationVariables,
} from '../../../graphql/queries/types/ChildConsultationnotes';
import {
  ChildGoals as ChildGoalData,
  ChildGoalsVariables as ChildGoalVariables,
  ChildGoals_childConsultations,
  ChildGoals_childConsultations_goalRecords,
} from '../../../graphql/queries/types/ChildGoals';
import {
  ChildTasks as ChildTaskData,
  ChildTasksVariables as ChildTaskVariables,
  ChildTasks_childConsultations,
  ChildTasks_childConsultations_activities,
  ChildTasks_childConsultations_customActivities,
  ChildTasks_child_assignedActivities,
  ChildTasks_child_assignedCustomActivities,
} from '../../../graphql/queries/types/ChildTasks';
import { GoalData, GoalVariables, Goal_goal } from '../../../graphql/queries/types/Goal';
import { GenderEnum } from '../../../types/graphql-global-types';
import { Spin } from 'antd';
import { LoadingContainer } from './layout/styles';
import { TherapistContext } from '../TherapistContextt';
import {
  addDays,
  addHours,
  beginningOfToday,
  dateTime,
  getNearestDateInFuture,
  isBetween,
  isPast,
  isToday,
  minusHours,
  month,
  shortDate,
  time,
  weekday,
  year,
} from '../../../util/DateAndTime';
import { CHILD_GOALS } from '../../../graphql/queries/childGoal';
import { CHILD_TASKS } from '../../../graphql/queries/childTasks';
import { GOAL_QUERY } from '../../../graphql/queries/goal';

// ====================== Notes ======================

// DEFAULT_STATUS = accepted: false && published: false && canceled: false
// IS_PAST = Date.parse(consultation time) < Date.now()
// IS_FUTURE = !IS_PAST
// TODAY = consultation time is the same Date same Month same Year as today
// MISSED = DEFAULT_STATUS && IS_PAST
// NEED_TO_ACCEPT = DEFAULT_STATUS && IS_FUTURE
// History_Task = canceled: true || published: true || MISSED
// ToDo_Task = canceled: false || published: false || NEED_TO_ACCEPT

// 1. CanceledList = consultation that (canceled: true)
// 2. PublishedList = consultation that (published: true)
// 3. MissedList = consultation that MISSED
// 4. ToDoList = consultation that is ToDo_Task
// 5. TodayPlanTodo = ToDo_Task && TODAY && (accepted: true) && IS_PAST
// 6. TodayConsultationTodo = ToDo_Task && TODAY && (accepted: true || false) && IS_FUTURE
// 7. ToBeAcceptTodo = ToDo_Task && (accepted: false)
// 8. CreateNotesToDo = ToDo_Task && IS_PAST && (accepted: true)
// 9. nextVideoCall = ToDo_Task && IS_FUTURE && (accepted: true)

// ====================== End of Notes ======================

export interface ConsultationsDateObj extends ChildConsultationNotes_consultations {
  date: number;
  time: string;
  weekday: string;
  month: string;
  year: string;
  twoHourBeforeConsultationMilliseconds: number;
  twoHourBeforeConsultationShortDate: string;
  consultationStartMilliseconds: number;
  consultationStartShortDate: string;
  consultationStartDate: string;
  consultationEndMilliseconds: number;
  consultationEndShortDate: string;
  consultationEndTime: string;
  thirtySixHourAfterConsultationEndMilliseconds: number;
  thirtySixHourAfterConsultationEndShortDate: string;
  thirtySixHourAfterConsultationEndDate: string;
  isNextConsultation: boolean;
}

export interface GoalsObj extends Goal_goal {
  goalSequence: string;
}

export interface ConsultationsAndPlanObj extends ConsultationsDateObj {
  type: string;
  status: string;
  title: string;
  consultationUrl: string;
  cnConsultationUrl: string;
  planSequence: string;
  uploadedVideos: UploadedVideosObj[];
}

export interface ModifyChildConsultationTaskObj extends ChildTasks_childConsultations {
  modifyActivities: PlanActivityObj[];
  modifyCustomActivities: PlanCustomActivityObj[];
}

export interface PlanActivityObj extends ChildTasks_childConsultations_activities {
  allTimeCheckInCount: number;
}

export interface PlanCustomActivityObj extends ChildTasks_childConsultations_customActivities {
  allTimeCheckInCount: number;
}

export interface ModifyChildConsultationGoalObj extends ChildGoals_childConsultations {
  modifyGoalRecords: PlanGoalObj[];
}

export interface PlanGoalObj extends ChildGoals_childConsultations_goalRecords {
  new: boolean;
  changedInNumerator: number;
}

export interface MatchChildIdParams {
  childId: string;
}

interface State {
  child: ChildConsultationNotes_child;
  rescheduleConsultation: ConsultationsAndPlanObj[];
  postConsultation: ConsultationsAndPlanObj[];
  nextConsultation: ConsultationsAndPlanObj[];
  ongoingConsultation: ConsultationsAndPlanObj[];
  publishedPlan: ConsultationsAndPlanObj[];
  nextPlan: ConsultationsAndPlanObj[];
  duePlan: ConsultationsAndPlanObj[];
  todayTodo: ConsultationsAndPlanObj[];
  consultationTodo: ConsultationsAndPlanObj[];
  planTodo: ConsultationsAndPlanObj[];
  childGoalConsultations: ModifyChildConsultationGoalObj[];
  childTaskConsultations: ModifyChildConsultationTaskObj[];
  assignedActivities: ChildTasks_child_assignedActivities[];
  assignedCustomActivities: ChildTasks_child_assignedCustomActivities[];
  goals: GoalsObj[];
  showUserInfoDrawer: boolean;
  setShowUserInfoDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  showActivityModal: boolean;
  setShowActivityModal: React.Dispatch<React.SetStateAction<boolean>>;
  showCustomActivityModal: boolean;
  setShowCustomActivityModal: React.Dispatch<React.SetStateAction<boolean>>;
  showGoalModal: boolean;
  setShowGoalModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultChild: ChildConsultationNotes_child = {
  id: '',
  name: '',
  gender: GenderEnum.male,
  dob: '',
  pictureUrl: '',
  client: {
    id: '',
    user: {
      id: '',
      uuid: '',
      name: '',
      locale: '',
      country: '',
    },
    consultationTimesRemain: 0,
    membershipDue: '',
  },
  product: {
    id: '',
    name: '',
    description: '',
  },
  childSixMonthGoal: {
    id: '',
    description: '',
  },
  nextConsultation: { id: '', consultationTime: '' },
  previousConsultation: { id: '', consultationTime: '' },
  consultations: [],
};

const defaultTherapistChildContext: State = {
  child: defaultChild,
  rescheduleConsultation: [],
  postConsultation: [],
  nextConsultation: [],
  ongoingConsultation: [],
  publishedPlan: [],
  nextPlan: [],
  duePlan: [],
  todayTodo: [],
  consultationTodo: [],
  planTodo: [],
  childGoalConsultations: [],
  childTaskConsultations: [],
  assignedActivities: [],
  assignedCustomActivities: [],
  goals: [],
  showUserInfoDrawer: false,
  setShowUserInfoDrawer: () => {},
  showActivityModal: false,
  setShowActivityModal: () => {},
  showCustomActivityModal: false,
  setShowCustomActivityModal: () => {},
  showGoalModal: false,
  setShowGoalModal: () => {},
};

export const TherapistChildContext = React.createContext(defaultTherapistChildContext);

export const TherapistChildContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const { therapist } = useContext(TherapistContext);
  const { childId } = useParams<MatchChildIdParams>();
  const [consultations, setConsultations] = useState<ChildConsultationNotes_consultations[]>([]);
  const [showUserInfoDrawer, setShowUserInfoDrawer] = useState<boolean>(false);
  const [showActivityModal, setShowActivityModal] = useState<boolean>(false);
  const [showCustomActivityModal, setShowCustomActivityModal] = useState<boolean>(false);
  const [showGoalModal, setShowGoalModal] = useState<boolean>(false);

  const childConsultationInfo = useQuery<ChildConsultationData, ChildConsultationVariables>(CHILD_CONSULTATION_NOTES, {
    variables: { childId },
  });
  const {
    loading: childConsultationLoading,
    error: childConsultationError,
    data: childConsultationData,
  } = childConsultationInfo;
  const childGoalInfo = useQuery<ChildGoalData, ChildGoalVariables>(CHILD_GOALS, { variables: { childId } });
  const { loading: childGoalLoading, error: childGoalError, data: childGoalData } = childGoalInfo;
  const childTaskInfo = useQuery<ChildTaskData, ChildTaskVariables>(CHILD_TASKS, { variables: { childId } });
  const { loading: childTaskLoading, error: childTaskError, data: childTaskData } = childTaskInfo;
  const goalInfo = useQuery<GoalData, GoalVariables>(GOAL_QUERY, { variables: { childId } });
  const { loading: goalLoading, error: goalError, data: goalData } = goalInfo;

  useEffect(() => {
    if (childConsultationData && childConsultationData.child && childConsultationData.child.consultations.length > 0) {
      setConsultations(childConsultationData.child.consultations);
    }
  }, [childConsultationData]); // <-- only update when data is changed

  if (childConsultationLoading || childGoalLoading || childTaskLoading || goalLoading) {
    return (
      <LoadingContainer>
        <Spin size="large" />
      </LoadingContainer>
    );
  }
  if (
    childConsultationError ||
    childConsultationData === undefined ||
    childGoalError ||
    childGoalData === undefined ||
    childTaskError ||
    childTaskData === undefined ||
    goalError ||
    goalData === undefined
  ) {
    return <p>Error...</p>;
  }

  const { child } = childConsultationData;
  const { childConsultations: childGoalConsultations } = childGoalData;
  const {
    childConsultations: childTaskConsultations,
    child: { assignedActivities, assignedCustomActivities },
  } = childTaskData;
  const {
    child: { goals },
  } = goalData;

  // define goal list sequence
  const modifyGoals = goals.map((goal, index) => {
    const result: GoalsObj = goal as GoalsObj;
    result.goalSequence = `${index + 1}`;
    return result;
  });

  // add is new assigned goal data to child consultation goal records
  const modifyChildGoalConsultations: ModifyChildConsultationGoalObj[] = childGoalConsultations.map(
    (consultation, index) => {
      const result: ModifyChildConsultationGoalObj = consultation as ModifyChildConsultationGoalObj;
      result.modifyGoalRecords = consultation.goalRecords.map((goalRecord, grIndex) => {
        const result1: PlanGoalObj = goalRecord as PlanGoalObj;
        const comparePreviousNumerator =
          goalRecord.currentNumerator - childGoalConsultations[index - 1]?.goalRecords[grIndex]?.currentNumerator;
        if (isNaN(comparePreviousNumerator)) {
          result1.new = true;
          result1.changedInNumerator = 0;
        } else if (comparePreviousNumerator === 0) {
          result1.new = false;
          result1.changedInNumerator = 0;
        } else {
          result1.new = false;
          result1.changedInNumerator = comparePreviousNumerator;
        }
        return result1;
      });
      return result;
    },
  );

  // add all time check in count data to child consultation task
  const modifyChildTaskConsultations: ModifyChildConsultationTaskObj[] = childTaskConsultations.map((task) => {
    const result: ModifyChildConsultationTaskObj = task as ModifyChildConsultationTaskObj;
    result.modifyActivities = task.activities.map((activity) => {
      const result1: PlanActivityObj = activity as PlanActivityObj;
      result1.allTimeCheckInCount = assignedActivities.filter((aac) => aac.id === activity.id)[0].checkInCount;
      return result1;
    });
    result.modifyCustomActivities = task.customActivities.map((customActivity) => {
      const result2: PlanCustomActivityObj = customActivity as PlanCustomActivityObj;
      result2.allTimeCheckInCount = assignedCustomActivities.filter(
        (aac) => aac.id === customActivity.id,
      )[0].checkInCount;
      return result2;
    });
    return result;
  });

  const origAllConsultations = consultations.length > 0 ? consultations : [];

  // find out accepted next consultation
  const nextConsultationList: Date[] = [];
  origAllConsultations.forEach((consultation) => {
    const date = new Date(consultation.consultationTime);
    if (Date.parse(consultation.consultationTime) > Date.now() && consultation.accepted && !consultation.published) {
      nextConsultationList.push(date);
    }
  });
  const nextConsultationDate = getNearestDateInFuture(nextConsultationList, beginningOfToday);

  // all consultation data factory
  const shortDateTime = (date: Date) => {
    const ShortDate = shortDate(date, therapist.user.locale);
    const Time = time(date, therapist.user.locale);
    return `${ShortDate}, ${Time}`;
  };
  const allConsultationsWithDateTime: ConsultationsDateObj[] = origAllConsultations.map((consultation) => {
    const result: ConsultationsDateObj = consultation as ConsultationsDateObj;
    const consultationStart = new Date(consultation.consultationTime);
    const consultationEnd = addHours(1, new Date(consultation.consultationTime));
    const twoHourBeforeConsultation = minusHours(2, new Date(consultation.consultationTime));
    const thirtySixHourAfterConsultation = addDays(1, addHours(13, new Date(consultation.consultationTime)));
    result.date = consultationStart.getDate();
    result.time = time(consultationStart, therapist.user.locale);
    result.weekday = weekday(consultationStart, therapist.user.locale, 'short');
    result.month = month(consultationStart, therapist.user.locale, 'short');
    result.year = year(consultationStart, therapist.user.locale);
    result.twoHourBeforeConsultationMilliseconds = twoHourBeforeConsultation.getTime();
    result.twoHourBeforeConsultationShortDate = shortDateTime(twoHourBeforeConsultation);
    result.consultationStartMilliseconds = consultationStart.getTime();
    result.consultationStartShortDate = shortDateTime(consultationStart);
    result.consultationStartDate = dateTime(consultationStart, therapist.user.locale);
    result.consultationEndMilliseconds = consultationEnd.getTime();
    result.consultationEndShortDate = shortDateTime(consultationEnd);
    result.consultationEndTime = time(consultationEnd, therapist.user.locale);
    result.thirtySixHourAfterConsultationEndMilliseconds = thirtySixHourAfterConsultation.getTime();
    result.thirtySixHourAfterConsultationEndShortDate = shortDateTime(thirtySixHourAfterConsultation);
    result.thirtySixHourAfterConsultationEndDate = dateTime(thirtySixHourAfterConsultation, therapist.user.locale);
    result.isNextConsultation = `${consultationStart}` === `${nextConsultationDate}`;
    return result;
  });

  const consultationsList: ConsultationsAndPlanObj[] = [];
  const planList: ConsultationsAndPlanObj[] = [];

  // sorting consultation from allConsultationsWithDateTime, and define each data for consultationList
  allConsultationsWithDateTime.forEach((consultation) => {
    const result: ConsultationsAndPlanObj = consultation as ConsultationsAndPlanObj;
    result.type = 'consultation';
    result.consultationUrl = therapist.consultationUrl ? therapist.consultationUrl : 'no zoom link';
    if (child.client.user.country == 'cn') {
      result.consultationUrl = therapist.cnConsultationUrl ? therapist.cnConsultationUrl : 'no zoom link';
    }
    result.title =
      therapist.user.locale === 'en'
        ? `${
            t('therapist.childInfo.general.todoType.consultation') +
            ' ' +
            t('therapist.childInfo.general.taskTitle.with') +
            ' ' +
            child.client.user.name
          }`
        : `${
            t('therapist.childInfo.general.taskTitle.with') +
            ' ' +
            child.client.user.name +
            ' ' +
            t('therapist.childInfo.general.todoType.consultation')
          }`;
    if (consultation.canceled) {
      result.status = 'consultationReschedule';
    } else if (consultation.accepted && isPast(consultation.consultationEndMilliseconds)) {
      result.status = 'consultationPost';
    } else if (consultation.accepted && !isPast(consultation.consultationStartMilliseconds)) {
      result.status = 'consultationNext';
    } else if (
      consultation.accepted &&
      isBetween(consultation.consultationStartMilliseconds, consultation.consultationEndMilliseconds)
    ) {
      result.status = 'consultationOngoing';
    } else {
      result.status = 'consultationUndefined';
      result.title = `Waiting admin to take action`;
    }
    if (result.status !== 'undefined') {
      consultationsList.push(JSON.parse(JSON.stringify(result)));
    }
  });

  // filter plan from allConsultationsWithDateTime, and define each type and status for planList
  allConsultationsWithDateTime.forEach((consultation) => {
    const result: ConsultationsAndPlanObj = consultation as ConsultationsAndPlanObj;
    result.type = 'plan';
    if (consultation.published) {
      result.status = 'planPublished';
    } else if (
      consultation.accepted &&
      isBetween(
        consultation.twoHourBeforeConsultationMilliseconds,
        consultation.thirtySixHourAfterConsultationEndMilliseconds,
      )
    ) {
      result.status = 'planNext';
    } else if (consultation.accepted && isPast(consultation.thirtySixHourAfterConsultationEndMilliseconds)) {
      result.status = 'planOverdue';
    } else {
      result.status = 'planUndefined';
      result.title = `Not a plan`;
    }
    if (result.status !== 'undefined') {
      planList.push(JSON.parse(JSON.stringify(result)));
    }
  });

  // define plan list sequence and title
  planList.map((plan, index) => {
    const result: ConsultationsAndPlanObj = plan as ConsultationsAndPlanObj;
    result.planSequence = `${index + 1}`;
    const sequence = index + 1;
    const publishedPlanTitle =
      therapist.user.locale === 'en'
        ? `${
            t('therapist.childInfo.general.todoType.plan') +
            ' ' +
            sequence +
            ' ' +
            t('therapist.childInfo.general.taskTitle.for') +
            ' ' +
            child.client.user.name
          }`
        : `${
            child.client.user.name +
            ' ' +
            t('therapist.childInfo.general.taskTitle.for') +
            ' ' +
            t('therapist.childInfo.general.todoType.plan') +
            sequence
          }`;
    const nextPlanTitle =
      therapist.user.locale === 'en'
        ? `${
            t('therapist.childInfo.general.button.create') +
            ' ' +
            t('therapist.childInfo.general.todoType.plan') +
            ' ' +
            sequence +
            ' ' +
            t('therapist.childInfo.general.taskTitle.for') +
            ' ' +
            child.client.user.name
          }`
        : `${
            t('therapist.childInfo.general.button.create') +
            ' ' +
            child.client.user.name +
            ' ' +
            t('therapist.childInfo.general.taskTitle.for') +
            ' ' +
            t('therapist.childInfo.general.todoType.plan') +
            sequence
          }`;

    if (plan.status === 'planPublished') {
      result.title = publishedPlanTitle;
    } else if (plan.status === 'planNext') {
      result.title = nextPlanTitle;
    } else {
      result.title = nextPlanTitle;
    }
    return result;
  });

  // filter consultation by status factory
  const consultationsListStatusFilter = (status: string) => {
    return consultationsList.filter((consultation) => consultation.status === status);
  };
  const rescheduleConsultation: ConsultationsAndPlanObj[] = consultationsListStatusFilter('consultationReschedule');
  const postConsultation: ConsultationsAndPlanObj[] = consultationsListStatusFilter('consultationPost');
  const nextConsultation: ConsultationsAndPlanObj[] = consultationsListStatusFilter('consultationNext');
  const ongoingConsultation: ConsultationsAndPlanObj[] = consultationsListStatusFilter('consultationOngoing');

  // filter plan by status factory
  const planListStatusFilter = (status: string) => {
    return planList.filter((consultation) => consultation.status === status);
  };
  const publishedPlan: ConsultationsAndPlanObj[] = planListStatusFilter('planPublished');
  const nextPlan: ConsultationsAndPlanObj[] = planListStatusFilter('planNext');
  const duePlan: ConsultationsAndPlanObj[] = planListStatusFilter('planOverdue');

  // todoList factory
  const todayTodo: ConsultationsAndPlanObj[] = [];
  const consultationTodo: ConsultationsAndPlanObj[] = [...nextConsultation, ...ongoingConsultation];
  const planTodo: ConsultationsAndPlanObj[] = [...nextPlan, ...duePlan];
  const todoList: ConsultationsAndPlanObj[] = [...nextConsultation, ...ongoingConsultation, ...nextPlan, ...duePlan];
  todoList.forEach((consultation) => {
    if (isToday(beginningOfToday, new Date(consultation.consultationTime))) {
      todayTodo.push(consultation);
    }
  });

  return (
    <TherapistChildContext.Provider
      value={{
        child,
        rescheduleConsultation: rescheduleConsultation.reverse(),
        postConsultation: postConsultation.reverse(),
        nextConsultation: nextConsultation,
        ongoingConsultation: ongoingConsultation,
        publishedPlan: publishedPlan.reverse(),
        nextPlan: nextPlan,
        duePlan: duePlan,
        todayTodo: todayTodo,
        consultationTodo: consultationTodo,
        planTodo: planTodo,
        childGoalConsultations: modifyChildGoalConsultations,
        childTaskConsultations: modifyChildTaskConsultations,
        assignedActivities: assignedActivities,
        assignedCustomActivities: assignedCustomActivities,
        goals: modifyGoals,
        showUserInfoDrawer,
        setShowUserInfoDrawer,
        showActivityModal,
        setShowActivityModal,
        showCustomActivityModal,
        setShowCustomActivityModal,
        showGoalModal,
        setShowGoalModal,
      }}
    >
      {children}
    </TherapistChildContext.Provider>
  );
};
