import React from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, Typography } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { ChildTasks_child_assignedCustomActivities } from '../../../../../../../graphql/queries/types/ChildTasks';
import { CollapseContentBox } from '../styles';
import SkillDetails from './component/SkillDetails';
import CheckInStatus from './component/CheckInStatus';
import ActivityHistory from './component/ActivityHistory';

interface Props {
  customActivity: ChildTasks_child_assignedCustomActivities;
}

const CollapseContent = ({ customActivity }: Props) => {
  const { t } = useTranslation();
  const { Title } = Typography;
  const { Panel } = Collapse;

  return (
    <CollapseContentBox
      bordered={false}
      defaultActiveKey={['1', '2']}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 90} translate={undefined} />}
      className="site-collapse-custom-collapse"
      expandIconPosition="end"
    >
      <Panel
        header={
          <Title level={5} style={{ marginBottom: '0' }}>
            {t('therapist.childInfo.activity.activityModal.details')}
          </Title>
        }
        key="1"
      >
        <SkillDetails customActivity={customActivity} />
      </Panel>
      <Panel
        header={
          <Title level={5} style={{ marginBottom: '0' }}>
            {t('therapist.childInfo.activity.activityModal.checkInStatus')}
          </Title>
        }
        key="2"
      >
        <CheckInStatus customActivity={customActivity} />
      </Panel>
      <Panel
        header={
          <Title level={5} style={{ marginBottom: '0' }}>
            {t('therapist.childInfo.activity.activityModal.history')}
          </Title>
        }
        key="3"
      >
        <ActivityHistory customActivity={customActivity} />
      </Panel>
    </CollapseContentBox>
  );
};

export default CollapseContent;
