import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Space, Typography } from 'antd';
import { ChildTasks_child_assignedCustomActivities } from '../../../../../../../graphql/queries/types/ChildTasks';
import { renderStringToList } from '../../../../../../../util/renderStringToList';
import { GET_CUSTOM_ACTIVITIES_FOLDER_PATH, getSignedUrl } from '../../../../../../../helpers/functions';

interface Props {
  customActivity: ChildTasks_child_assignedCustomActivities;
}

interface Attachment {
  id: string;
  attachmentType: string;
  data: string;
  signedUrl?: string;
}

const TherapistView = ({ customActivity }: Props) => {
  const { Title, Text } = Typography;
  const { t } = useTranslation();
  const parentInteraction = renderStringToList(customActivity.parentInteraction);
  const [attachmentsWithUrls, setAttachmentsWithUrls] = useState<Attachment[]>([]);

  useEffect(() => {
    const fetchSignedUrls = async () => {
      if (customActivity.customActivityAttachments && customActivity.customActivityAttachments?.length > 0) {
        const attachmentsWithSignedUrls = await Promise.all(
          customActivity.customActivityAttachments.map(async (attachment: any) => {
            if (attachment.attachmentType === 'file') {
              const signedUrl = await getSignedUrl(
                GET_CUSTOM_ACTIVITIES_FOLDER_PATH(
                  customActivity.skill.id,
                  customActivity.skill.name,
                  customActivity.name,
                ),
                attachment.data,
                process.env.REACT_APP_AWS_UPLOADER_S3_BUCKET ?? '',
              );
              return { ...attachment, signedUrl };
            }
            return attachment;
          }),
        );
        setAttachmentsWithUrls(attachmentsWithSignedUrls);
      }
    };
    fetchSignedUrls();
  }, [customActivity]);

  return (
    <>
      <Title level={3}>{customActivity.name}</Title>
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        <div>
          <Title level={5}>{t('therapist.childInfo.activity.general.activity')}</Title>
          <Text>{customActivity.introduction}</Text>
        </div>
        {/* <div>
          <Title level={5}>{t('therapist.childInfo.activity.activityModal.activitySummary')}</Title>
          <Text>{customActivity.summary}</Text>
        </div> */}
        <div>
          <Title level={5}>{t('therapist.childInfo.activity.activityModal.parentInteraction')}</Title>
          <Text>{parentInteraction}</Text>
        </div>
        {attachmentsWithUrls.length > 0 && (
          <div>
            <Title level={5}>{t('therapist.consultationScreen.activities.customActivityCard.attachments')}</Title>
            {attachmentsWithUrls.map((attachment) => (
              <div key={attachment.id} style={{ marginBottom: '5px' }}>
                {attachment.attachmentType === 'file' ? (
                  <a href={attachment.signedUrl ?? '#'} target="_blank" rel="noopener noreferrer">
                    {attachment.data.split('_').slice(1).join('_')}
                  </a>
                ) : (
                  <a href={attachment.data} target="_blank" rel="noopener noreferrer">
                    {attachment.data}
                  </a>
                )}
              </div>
            ))}
          </div>
        )}
      </Space>
    </>
  );
};

export default TherapistView;
