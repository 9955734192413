import React from 'react';
import { Card, Statistic, Timeline, Typography } from 'antd';
import {
  ChildTasks_child_assignedActivities,
  ChildTasks_child_assignedCustomActivities,
} from '../../../../../../../../graphql/queries/types/ChildTasks';
import { BackgroundColor } from '../../../../../../../Client/WordTrackingRoute/styles';
import { NotionBlueLight, NotionGrayLight, NotionPurpleLight } from '../../../styles';
import { HistoryBadge, HistoryListContainer } from '../../styles';
import CheckInChart from './CheckInChart';

interface Props {
  customActivity: ChildTasks_child_assignedCustomActivities;
}

const CheckInStatus = ({ customActivity }: Props) => {
  const { Text } = Typography;

  const UserBadgeColor = (userType: string) => {
    if (userType === 'therapist') {
      return NotionBlueLight;
    } else {
      return NotionPurpleLight;
    }
  };

  return (
    <>
      Upcoming feature
      {/* <Card>
        <Statistic title="Total Check-ins" value={activity.checkInCount} />
        <CheckInChart></CheckInChart>
      </Card>
      <Timeline style={{ paddingTop: '30px' }}>
        <Timeline.Item>
          <HistoryListContainer>
            <Text>Check in</Text>
            <HistoryBadge BackgroundColor={BackgroundColor} style={{ margin: '0 5px' }}>
              <Text>+4</Text>
            </HistoryBadge>
            <Text>by</Text>
            <HistoryBadge BackgroundColor={UserBadgeColor('parent')} style={{ margin: '0 5px' }}>
              <Text>雯妈</Text>
            </HistoryBadge>
          </HistoryListContainer>
          <HistoryListContainer>
            <Text type="secondary">during consultation 5</Text>
            <HistoryBadge BackgroundColor={NotionGrayLight}>
              <Text>2022-02-14</Text>
            </HistoryBadge>
          </HistoryListContainer>
        </Timeline.Item>
        <Timeline.Item>
          <HistoryListContainer>
            <Text>Check in</Text>
            <HistoryBadge BackgroundColor={BackgroundColor} style={{ margin: '0 5px' }}>
              <Text>+6</Text>
            </HistoryBadge>
            <Text>by</Text>
            <HistoryBadge BackgroundColor={UserBadgeColor('parent')} style={{ margin: '0 5px' }}>
              <Text>雯妈</Text>
            </HistoryBadge>
          </HistoryListContainer>
          <HistoryListContainer>
            <Text type="secondary">during consultation 3</Text>
            <HistoryBadge BackgroundColor={NotionGrayLight}>
              <Text>2021-10-07</Text>
            </HistoryBadge>
          </HistoryListContainer>
        </Timeline.Item>
        <Timeline.Item>
          <HistoryListContainer>
            <Text>Check in</Text>
            <HistoryBadge BackgroundColor={BackgroundColor} style={{ margin: '0 5px' }}>
              <Text>+1</Text>
            </HistoryBadge>
            <Text>by</Text>
            <HistoryBadge BackgroundColor={UserBadgeColor('parent')} style={{ margin: '0 5px' }}>
              <Text>雯妈</Text>
            </HistoryBadge>
          </HistoryListContainer>
          <HistoryListContainer>
            <Text type="secondary">during consultation 2</Text>
            <HistoryBadge BackgroundColor={NotionGrayLight}>
              <Text>2021-09-14</Text>
            </HistoryBadge>
          </HistoryListContainer>
        </Timeline.Item>
      </Timeline> */}
    </>
  );
};

export default CheckInStatus;
